<template>
  <div>
    <div class="pauseSwitcher">
      <span v-if="view === 'block'">{{ $t("time.pause.add") }}</span>
      <span v-if="view === 'list'">{{ $t("applicants.title") }}</span>
      <a href="#" v-if="view === 'block'" @click.prevent="view = 'list'"
        ><pauseIcon id="coffee-mug" class="pauseIcon"
      /></a>
      <a href="#" v-if="view === 'list'" @click.prevent="view = 'block'"
        ><pauseIcon id="table" class="pauseIcon"
      /></a>
    </div>
    <div v-if="view === 'block'">
      <viewDay v-if="$store.state.tableau.view === 'day'" />
      <viewWeek v-if="$store.state.tableau.view === 'week'" />
    </div>
    <div v-if="view === 'list'">
      <pause />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    viewDay() {
      return import("./viewDay");
    },
    viewWeek() {
      return import("./viewWeek");
    },
    pause() {
      return import("@/components/business/time/pause");
    },
    pauseIcon() {
      return import("./pauseIcon");
    }
  },
  props: {},
  data() {
    return {
      view: "block"
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
